/**
 * First file imported by the app
 *
 * This should NOT contain ANY dependency
 * except the ones necessary to show the preloader
 * so be careful
 */
import {
  createApp
  // reactive
} from 'vue'

import Preloader from './Preloader.vue'
const preloaderApp = createApp(Preloader)
// let preloaderInstance = reactive({ visible: true }) // default dummy value
if (!('__PRERENDER_INJECTED' in window)) {
  // preloaderInstance = preloaderApp.mount('#app-loading-container')

  // horrible hack (TM)
  window.preloaderInstance = preloaderApp.mount('#app-loading-container')
}

import('./main.js').then(main => {
  // doesn't work
  // main.preloaderInstance = preloaderInstance
  return main
}).catch(e => {
  console.error(e)
})
